function UpworkLogo() {
  return (
    <div>
      <svg
        width="48"
        height="47"
        viewBox="0 0 48 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_128_13)">
          <path
            d="M44.4557 0H3.54624C1.58784 0 0 1.50776 0 3.36708V43.631C0 45.4922 1.58784 47 3.54624 47H44.4557C46.4141 47 48 45.4904 48 43.631V3.36708C48 1.50776 46.4141 0 44.4557 0Z"
            fill="#A6E102" // Cyberworld Green
          />
          <path
            d="M35.2244 27.3295C33.3351 27.3295 31.5668 26.5456 29.9579 25.2691L30.3495 23.468L30.3668 23.4022C30.7163 21.4846 31.8222 18.2642 35.2244 18.2642C37.7761 18.2642 39.8516 20.3002 39.8516 22.8006C39.8439 25.2935 37.7684 27.3295 35.2244 27.3295ZM35.2244 13.6676C30.8775 13.6676 27.5079 16.4368 26.139 20.9902C24.0462 17.9126 22.4641 14.2184 21.5367 11.107H16.8596V23.0413C16.8596 25.3931 14.9031 27.3107 12.5031 27.3107C10.1031 27.3107 8.14858 25.3931 8.14858 23.0413V11.107H3.46954V23.0413C3.45226 27.9293 7.51114 31.9412 12.4955 31.9412C17.4798 31.9412 21.5387 27.9293 21.5387 23.0413V21.0391C22.4487 22.8984 23.5623 24.7746 24.9159 26.4441L22.0494 39.6548H26.8398L28.9153 30.0725C30.7355 31.2155 32.8283 31.9318 35.2283 31.9318C40.3585 31.9318 44.5345 27.8108 44.5345 22.7818C44.5307 17.7622 40.3547 13.6676 35.2244 13.6676Z"
            fill="#181818"
          />
        </g>
        <defs>
          <clipPath id="clip0_128_13">
            <rect width="48" height="47" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default UpworkLogo;
