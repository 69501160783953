import src from "../../assets/images/x-green-small.png";

function TwitterLogo() {
  return (
    <div>
      <img src={src} alt="X Logo" />
    </div>
  );
}

export default TwitterLogo;
